import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { environment } from "environments/environment"
import { BehaviorSubject } from "rxjs"
import { catchError, take } from "rxjs/operators"

const BASE_URL = environment.cwanGptWsBaseUrl
const CONFLUENCE_CATEGORIES_URL = `${BASE_URL}/confluence/categories`

@Injectable({
  providedIn: 'root',
})
export class ConfluenceService {
  constructor(private httpClient: HttpClient) {}
  private categories = new BehaviorSubject<string[]>([])
  currentCategories = this.categories.asObservable()

  updateCategories(categories: string[]) {
    this.categories.next(categories)
  }

  async getCategories() {
    return this.httpClient
      .get<string[]>(CONFLUENCE_CATEGORIES_URL)
      .pipe(
        take(1),
        catchError((error) => {
          console.log(error)
          return Promise.reject(error)
        })
      )
      .toPromise()
  }
}