import {
  ConfluenceService
} from '@cwan-gpt-ui/services'
import { Component, EventEmitter, OnInit, Output } from '@angular/core'

@Component({
  selector: 'confluence',
  templateUrl: './confluence.component.html',
  styleUrls: ['./confluence.component.scss']
})
export class ConfluenceComponent implements OnInit {

  categories: string[] = []
  selectedCategories: string[] = []
  @Output() createSession = new EventEmitter()

  constructor(
    private readonly confluenceService: ConfluenceService,
  ) {}

  ngOnInit(): void {
    this.loadAsync()
  }

  async loadAsync() {
    this.categories = await this.confluenceService.getCategories()
    this.selectCategories(this.selectedCategories)
  }

  selectCategories(selectedCategories: string[]) {
    this.selectedCategories = selectedCategories
    if (selectedCategories.length > 0) {
      this.confluenceService.updateCategories(selectedCategories)
    } else {
      this.confluenceService.updateCategories([])
    }
    this.createSession.emit()
  }

}
