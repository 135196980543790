import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { GlobalResourcesFileUploadResponse } from '@cwan-gpt-ui/models'
import { environment } from 'environments/environment'
import { take } from 'rxjs/operators'

const BASE_URL = environment.cwanGptWsBaseUrl
const GLOBAL_RESOURCES_URL = `${BASE_URL}/resources`

export interface FileNotFoundError {
  type: 'FILE_NOT_FOUND'
  message: string
}

export interface GeneralError {
  type: 'GENERAL_ERROR'
  message: string
}

export type ResourceError = FileNotFoundError | GeneralError

@Injectable({
  providedIn: 'root',
})
export class GlobalResourcesService {
  constructor(private readonly httpClient: HttpClient) {}

  async getFileContent(path: string): Promise<string> {
    try {
      const presignedUrl = await this.getGlobalResourceUrl(path)
      return await this.fetchFromS3(presignedUrl.url)
    } catch (error: any) {
      console.error(`Failed to load file content for ${path}:`, error)

      // Handle HTTP 404 errors
      if (error?.status === 404) {
        const message = `Document '${path}' does not exist yet`
        console.warn(message)
        throw { type: 'FILE_NOT_FOUND', message } as ResourceError
      }

      // Handle S3 XML errors
      if (typeof error?.error === 'string' && error.error.includes('<?xml')) {
        const parsedError = this.parseS3Error(error.error)
        if (parsedError.code === 'NoSuchKey') {
          const message = `Document '${path}' does not exist yet`
          console.warn(message)
          throw { type: 'FILE_NOT_FOUND', message } as ResourceError
        } else {
          console.error(
            `S3 Error '${parsedError.code}': ${parsedError.message}`
          )
        }
      }

      console.error(
        'error from global-services/getFileContent while loading file content:',
        error
      )
      throw {
        type: 'GENERAL_ERROR',
        message: 'Failed to load file content',
      } as ResourceError
    }
  }

  async saveFileContent(path: string, content: string): Promise<void> {
    try {
      const presignedUrl = await this.putGlobalResourceUrl(path)
      await this.uploadToS3(presignedUrl.url, content)
    } catch (error: any) {
      console.error('Failed to save file content:', error)

      // Check if it's an S3 error response
      if (error?.error?.includes('<?xml')) {
        const parsedError = this.parseS3Error(error.error)

        throw {
          type: 'GENERAL_ERROR',
          message: parsedError.message || 'Failed to save file content',
        } as ResourceError
      }

      throw {
        type: 'GENERAL_ERROR',
        message: 'Failed to save file content',
      } as ResourceError
    }
  }

  private async getGlobalResourceUrl(
    path: string
  ): Promise<GlobalResourcesFileUploadResponse> {
    return this.httpClient
      .get<GlobalResourcesFileUploadResponse>(`${GLOBAL_RESOURCES_URL}/${path}`)
      .pipe(take(1))
      .toPromise()
  }

  private async putGlobalResourceUrl(
    path: string
  ): Promise<GlobalResourcesFileUploadResponse> {
    return this.httpClient
      .put<GlobalResourcesFileUploadResponse>(
        `${GLOBAL_RESOURCES_URL}/${path}`,
        null
      )
      .pipe(take(1))
      .toPromise()
  }

  private async fetchFromS3(presignedUrl: string): Promise<string> {
    return this.httpClient
      .get(presignedUrl, {
        responseType: 'text',
        headers: new HttpHeaders(),
        withCredentials: false,
      })
      .pipe(take(1))
      .toPromise()
  }

  private async uploadToS3(presignedUrl: string, content: string) {
    const blob = new Blob([content], { type: 'text/markdown' })
    return this.httpClient
      .put(presignedUrl, blob, {
        headers: new HttpHeaders({
          'Content-Type': 'text/markdown',
        }),
        withCredentials: false,
      })
      .pipe(take(1))
      .toPromise()
  }

  private parseS3Error(xmlString: string): { code: string; message: string } {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xmlString, 'text/xml')
    return {
      code: xmlDoc.querySelector('Error > Code')?.textContent ?? '',
      message: xmlDoc.querySelector('Error > Message')?.textContent ?? '',
    }
  }

  // ** image upload
  async uploadImageFile(path: string, file: File): Promise<void> {
    try {
      // Validate file type
      if (!file.type.startsWith('image/')) {
        throw {
          type: 'GENERAL_ERROR',
          message: 'Invalid file type. Only images are allowed.',
        } as ResourceError
      }

      const presignedUrl = await this.putGlobalResourceUrl(path)
      await this.uploadImageToS3(presignedUrl.url, file)
    } catch (error: any) {
      console.error('Failed to upload image:', error)

      // Check if it's an S3 error response
      if (error?.error?.includes('<?xml')) {
        const parsedError = this.parseS3Error(error.error)

        throw {
          type: 'GENERAL_ERROR',
          message: parsedError.message || 'Failed to upload image',
        } as ResourceError
      }

      throw {
        type: 'GENERAL_ERROR',
        message: error.message || 'Failed to upload image',
      } as ResourceError
    }
  }

  private async uploadImageToS3(presignedUrl: string, file: File) {
    return this.httpClient
      .put(presignedUrl, file, {
        headers: new HttpHeaders({
          'Content-Type': file.type,
        }),
        withCredentials: false,
      })
      .pipe(take(1))
      .toPromise()
  }

  async getImageUrl(path: string): Promise<string> {
    try {
      const presignedUrl = await this.getGlobalResourceUrl(path)
      return presignedUrl.url
    } catch (error: any) {
      console.error('Failed to get image URL:', error)

      if (error?.status === 404) {
        throw {
          type: 'FILE_NOT_FOUND',
          message: `Image '${path}' does not exist`,
        } as ResourceError
      }

      throw {
        type: 'GENERAL_ERROR',
        message: 'Failed to get image URL',
      } as ResourceError
    }
  }

  async transformMarkdownImageUrls(markdown: string): Promise<string> {
    if (!markdown) return markdown

    const imageRegex = /!\[([^\]]*)\]\(([^)]+)\)/g
    let transformedMarkdown = markdown

    let match
    while ((match = imageRegex.exec(markdown)) !== null) {
      const [fullMatch, altText, imagePath] = match

      try {
        let newPresignedUrl: string | null = null

        if (this.isAssetPath(imagePath)) {
          // ** note: this part was added for the initial integration of image upload to avoid replacing image urls for all envs.
          // all the images from /assets/user-guide-images folder were uploaded to S3.
          const fileName = this.getFileNameFromPath(imagePath)
          newPresignedUrl = await this.getImageUrl(`images/${fileName}`)
        } else if (this.needsS3UrlTransformation(imagePath)) {
          // Handle existing S3 URLs
          newPresignedUrl = await this.getPresignedUrl(imagePath)
        } else {
          continue // Skip other URLs
        }

        if (!newPresignedUrl) continue

        const newImageMarkdown = this.createImageMarkdown(
          altText,
          newPresignedUrl
        )
        transformedMarkdown = transformedMarkdown.replace(
          fullMatch,
          newImageMarkdown
        )
      } catch (error) {
        console.warn(`Failed to transform image URL for ${imagePath}:`, error)
        continue
      }
    }

    return transformedMarkdown
  }

  private isAssetPath(path: string): boolean {
    return path.startsWith('/assets/') || path.startsWith('./assets/')
  }

  private getFileNameFromPath(path: string): string {
    // Extract filename from path like '/assets/user-guide-images/cwic-specialist-create.png'
    return path.split('/').pop() ?? ''
  }

  private needsS3UrlTransformation(imagePath: string): boolean {
    return imagePath.includes('X-Amz-Signature')
  }

  private async getPresignedUrl(imagePath: string): Promise<string | null> {
    const baseImagePath = imagePath.split('?')[0].split('/images/').pop()
    if (!baseImagePath) return null

    return await this.getImageUrl(`images/${baseImagePath}`)
  }

  private createImageMarkdown(altText: string, url: string): string {
    return `![${altText}](${url})`
  }
}
